import React from 'react'
import styles from './CardVideo.module.scss'
import ReactPlayer from 'react-player'


const CardVideo = ({item}) => {

    return (
        <li className={styles.item}>
           
      <iframe
        className={styles.video}
        src="https://rutube.ru/play/embed/b2b06f5a6a2cfa0f674ec76e20f30417"
        allow="clipboard-write; autoplay"
        webkitAllowFullScreen
        mozallowfullscreen
        allowFullScreen
      ></iframe>
    
        </li>
    )
}

export default CardVideo  
