import React, {useEffect} from 'react'
import { Link} from 'react-router-dom'
import { useNavigate } from "react-router";
import styles from './PageNotFound.module.scss'

export const PageNotFound = () => {

    const navigate = useNavigate();
    
    const goBack = () => navigate(-1);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <section className={styles.main}>
            <div className={styles.box}>
                <h3 className={styles.subtitle}>Хьюстон, у нас проблема</h3>
                <h2 className={styles.title}>404</h2>
                <p className={styles.text}>Страница не найдена</p>
                <Link className={styles.link} to='' onClick={goBack}>Cдать назад</Link>
            </div>
        </section>
        
    )
}