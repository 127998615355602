import React from 'react'
import styles from './Map.module.scss'
import { motion } from "framer-motion"
import ButtonBox from '../ButtonBox/ButtonBox'
import DetailMap from '../DetailMap/DetailMap'
import phone from '../../image/Map/phone.svg'
import email from '../../image/Map/email-icon.svg'
import address from '../../image/Map/address.svg'
import time from '../../image/Map/time.svg'
import company from '../../image/Map/company.svg'
import yt from '../../image/Footer/youtube_big.svg'

const titleAnimation = {
    hidden: {
        y: 200,
        opacity: 0,
    },

    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .5 }
    }
}

const cardAnimation = {
    hidden: {
        y: 100,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .7}
    } 
}


const Map = () => {


    return (
        <motion.section 
            className={styles.main}
            initial='hidden'
            whileInView='visible'
            viewport={{amount: 0.1, once: true}}
            >
            <motion.div className={styles.box}>
                <div className={styles.info}>
                    <motion.h2 className={styles.title} variants={titleAnimation}>Контакты <span>PERKY&#160;MOTH</span></motion.h2>
                    <motion.div 
                        className={styles.box_contacts}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                        >
                            <div className={styles.icon_box}>
                                <img className={styles.icon_contacts} src={phone} alt = 'icon'/>
                            </div>
                            <a className={styles.link_phone}  href="tel:+79015417070" target='_blank' rel='noopener noreferrer'>+7 (901) 541-70-70</a>
                    </motion.div>
                    <motion.div 
                        className={styles.box_contacts}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                        >
                            <div className={styles.icon_box}>
                                <img className={styles.icon_contacts} src={phone} alt = 'icon'/>
                            </div>
                            <a className={styles.link_phone}  href="tel:+79015413737" target='_blank' rel='noopener noreferrer'>+7 (901) 541-37-37</a>
                    </motion.div>
                    <motion.div 
                        className={styles.box_contacts}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                        >
                            <div className={styles.icon_box}>
                                <img className={styles.icon_contacts} src={email} alt = 'icon'/>
                            </div>
                            <a className={styles.link_email}  href="zakaz@perkymoh.ru" target='_blank' rel='noopener noreferrer'>zakaz@perkymoh.ru</a>
                    </motion.div>
                    <motion.div 
                        className={styles.box_contacts}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                        >
                            <div className={styles.icon_box}>
                                <img className={styles.icon_contacts} src={company} alt = 'icon'/>
                            </div>
                            <p className={styles.text}>ИП Лацужба Нонна Владимировна , ИНН&#160;771901073708</p>
                    </motion.div>
                    <motion.div 
                        className={styles.box_contacts}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                        >
                            <div className={styles.icon_box}>
                                <img className={styles.icon_contacts} src={address} alt = 'icon'/>
                            </div>
                            <p className={styles.text}>г. Москва, п. Сосенское б-р Веласкеса, д. 7, к. 6</p>
                    </motion.div>
                    <motion.div 
                        className={styles.box_contacts_social}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                        >
                            <div className={styles.icon_box}>
                                <img className={styles.icon_contacts} src={time} alt = 'icon'/>
                            </div>
                            <p className={styles.text}>Пн-Пт 09:00 - 18:00 Сб&#8205;-&#8205;Вс&#160;09:00&#160;-&#160;18:00</p>
                            <ButtonBox/>
                    </motion.div>
                    <div className={styles.box_yt}>
                        <p className={styles.text}>Подписывайтесь на наш канал:</p>
                        <a className={styles.link_yt} target='_blank' rel='noopener noreferrer' href='https://www.youtube.com/@PerkyMoth_world' aria-label='ютуб канал'>
                            <img className={styles.icon_yt} src={yt} alt = 'icon'/>
                        </a>
                    </div>
                </div>
                <motion.div 
                    className={styles.box_map}
                    variants={titleAnimation} 
                    initial='hidden'
                    whileInView='visible'
                    viewport={{amount: 0.05, once: true}}
                    >
                    <DetailMap/>
                </motion.div>
            </motion.div>
        </motion.section>
    )
}

export default Map